import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, history } from './Redux'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production',
  dsn: "https://d788928b5a534e7d9f2f29039824ab6a@sentry.goflighthealth.com/3",
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App  history={history} />
      </PersistGate>
    </Provider>
  </React.StrictMode >,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
