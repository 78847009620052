import ApiService from "../middleware/handleAxios";
import {getUser} from "./auth";
import {closeErrorOrSuccessScreen, openErrorOrSuccessScreen} from "./modal";

export const verifyByPhoneRequest = (data) => {
  const newRequest = {
    method: 'POST',
    endpoint: 'login',
    query: {
      ...data,
    },
  }

  return ApiService.apiCall(newRequest)
}

export const VERIFY_BY_PHONE = 'verification/VERIFY_BY_PHONE';
export const verifyByPhone = (data, history, slug, order, insuranceId, isNewPatientFlow) => dispatch => {
  dispatch ({
    type: VERIFY_BY_PHONE,
    payload: {...data},
  })
  verifyByPhoneRequest(data).then((res) => {
    dispatch({ type: VERIFY_BY_PHONE + '.SUCCESS', payload: res.data })
    dispatch(getUser(res.data.token))
    dispatch(openErrorOrSuccessScreen({
      isOpen: true,
      typeScreen: 'success',
      title: 'Account Verified!',
      subtitle: 'Signing you in...',
      nextAction: () => {
        if (order?.doctor?.id && order?.appointment?.id && isNewPatientFlow) {
          history.push(`/${slug}/confirmation/`)
        }
        dispatch(closeErrorOrSuccessScreen())
      }
    }))
  }).catch((error) => {
    if(error?.response?.status === 423) {
      dispatch(openErrorOrSuccessScreen({
        isOpen: true,
        typeScreen: 'error',
        title: 'Login Attempts Exceeded',
        subtitle: 'For your safety, we’ve temporarily locked your account due to multiple unsuccessful attempts. Please contact support to resolve the issue.',
        isChat: true,
        link: 'Try Another Method',
        onLink: `/${slug}/login`,
        unmountLink: `/${slug}/login`,
      }))
    }
    dispatch({
      type: VERIFY_BY_PHONE + '.FAILURE',
      payload: error?.response?.data,
    })
  })
}

export const RESEND_CODE = 'verification/RESEND_CODE';
export const resendCode = (data) => {
  return {
    method: 'POST',
    endpoint: 'verification/request_phone',
    query: {
      ...data
    },
    payload: {... data},
    type: RESEND_CODE,
  }
}

export const REQUEST_EMAIL = 'verification/REQUEST_EMAIL';
export const requestEmail = (data) => {
  return {
    method: 'POST',
    endpoint: 'verification/check_email',
    query: {
      ...data
    },
    payload: {... data},
    type: REQUEST_EMAIL,
  }
}

export const CLEAR_VERIFICATION_STATE = 'verification/CLEAR_VERIFICATION_STATE'
export const clearVerifyState = () => {
  return {
    type: CLEAR_VERIFICATION_STATE
  }
}

export const SET_FORM_FROM = 'verification/SET_FORM_FROM'
export const setFormFrom = (formFrom) => {
  return {
    type: SET_FORM_FROM,
    payload: {
      from: formFrom
    }
  }
}

export const VERIFY_PHONE = 'verification/VERIFY_PHONE'
export const verifyPhone = (data) => {
  return {
    method: 'POST',
    endpoint: 'verification/verify_phone',
    query: {
      ...data
    },
    type: VERIFY_PHONE,
  }
}

export const VERIFY_GOOGLE = 'verification/VERIFY_GOOGLE';
export const verifyGoogleAndApple = (data) => {
  return {
    method: 'POST',
    endpoint: 'verification/check_email',
    query: {
      ...data
    },
    payload: {... data},
    type: VERIFY_GOOGLE,
  }
}

export const SEND_CONFIRMATION = 'verification/SEND_CONFIRMATION';
export const sendConfirmation = (email) => {
  return {
    method: 'POST',
    endpoint: 'verification/request_email',
    query: {
      email,
    },
    type: SEND_CONFIRMATION,
  }
}

export const SEND_CODE_TO_EMAIL = 'verification/SEND_CODE_TO_EMAIL';
export const sendCodeToEmail = () => {
  return {
    method: 'POST',
    endpoint: 'verification/request_token',
    type: SEND_CODE_TO_EMAIL,
  }
}

export const VERIFY_EMAIL = 'verification/VERIFY_EMAIL';
export const verifyEmail = ({email, verification_code}) => {
  return {
    method: 'POST',
    endpoint: 'verification/verify_email',
    query: { email, verification_code},
    type: VERIFY_EMAIL,
  }
}

