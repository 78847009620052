import {useEffect, useRef} from "react";

type ICallback = () => void;

const useUnmount = (callback: ICallback): void => {
    const callbackRef = useRef(callback)
    useEffect(() => {
        return () => callbackRef.current();
    }, [])
}

export default useUnmount;