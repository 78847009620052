import {clearVerifyState} from './verification'
import ApiService from "../middleware/handleAxios";
import {closeErrorOrSuccessScreen, openErrorOrSuccessScreen} from "./modal";
import {clearAppointmentsState} from "./appointments";

export const signInRequest = (data) => {
  const newRequest = {
    method: 'POST',
    endpoint: 'login',
    query: {
      ...data
    },
  }
  return ApiService.apiCall(newRequest)
}

export const SIGN_IN = 'auth/SIGN_IN'
export const signIn = (data, history, slug, isNewPatientFlow = false, order = null, insuranceId = null) => dispatch => {
  dispatch({type: SIGN_IN})
  console.log(insuranceId)
  signInRequest(data).then((res) => {
    dispatch({
      type: SIGN_IN+'.SUCCESS',
      payload: res.data,
    })
    dispatch(openErrorOrSuccessScreen({
      isOpen: true,
      typeScreen: 'success',
      title: 'Account Verified!',
      subtitle: 'Signing you in...',
      nextAction: () => {
        if (order?.doctor?.id && order?.appointment?.id && isNewPatientFlow) {
          history.push(`/${slug}/confirmation/`)
        }
        dispatch(closeErrorOrSuccessScreen())
      }
    }))
    dispatch(getUser(res.data.token))
  }).catch((error) => {
    if(error?.response?.status === 423) {
      dispatch(openErrorOrSuccessScreen({
        isOpen: true,
        typeScreen: 'error',
        title: 'Login Attempts Exceeded',
        subtitle: 'For your safety, we’ve temporarily locked your account due to multiple unsuccessful attempts. Please contact support to resolve the issue.',
        isChat: true,
        link: 'Try again',
        onLink: `/${slug}/login`,
        unmountLink: `/${slug}/login`,
      }))
    }
    dispatch({ type: SIGN_IN+'.FAILURE', payload: error.response.data,})
  })
}

export const getUserRequest = (token) => {
  const newRequest = {
    endpoint: 'users/me',
    headers: { Authorization: `Bearer ${token}` }
  }
  return ApiService.apiCall(newRequest)
}

export const GET_USER_UNIT = 'auth/GET_USER_UNIT'
export const getUser = (token) => dispatch => {
  getUserRequest(token).then((res) => {
    dispatch({
      type: GET_USER_UNIT+'.SUCCESS',
      payload: res.data,
    })
  }).catch((error) => console.log(error))
}

export const SWITCH_AUTH_FORM = 'auth/SWITCH_AUTH_FROM'
export const switchAuthForm = (formState) => {
  return {
    payload: {
      isFormSwitched: !formState
    },
    type: SWITCH_AUTH_FORM
  }
}

export const SET_AUTH_FORM_VALUE = 'auth/SET_AUTH_FORM_VALUE'
export const setFormValues = (values) => {
  return {
    payload: {
      authFormValue: values
    },
    type: SET_AUTH_FORM_VALUE
  }
}

export const UPLOAD_AVATAR = 'auth/UPLOAD_AVATAR'
export const uploadAvatar = (id, data) => {
  return {
    method: 'PUT',
    endpoint: `users/${id}/upload_photo`,
    query: data,
    type: UPLOAD_AVATAR,
  }
}

export const logInByGoogleRequest = (token) => {
  const newRequest = {
    method: 'POST',
    endpoint: `login/google?idtoken=${token}`,
  }
  return ApiService.apiCall(newRequest)
}

export const LOGIN_GOOGLE = 'auth/LOGIN_GOOGLE'
export const logInByGoogle = (token, order, insuranceId, history, slug, isNewPatientFlow) => dispatch => {
  dispatch({ type: LOGIN_GOOGLE })
  logInByGoogleRequest(token).then((res) => {
    dispatch({
      type: LOGIN_GOOGLE + '.SUCCESS',
      payload: res.data,
    })
    dispatch(getUser(res.data.token))
    if (order?.doctor?.id && order?.appointment?.id && isNewPatientFlow) {
      history.push(`/${slug}/confirmation/`)
    }
  }).catch((error) => {
    dispatch({
      type: LOGIN_GOOGLE + '.FAILURE',
      payload: error,
    })
  })
}

export const logInByAppleRequest = (token, code) => {
  const newRequest = {
    method: 'POST',
    endpoint: `login/apple`,
    query: {
      idtoken: token,
      code: code
    }
  }
  return ApiService.apiCall(newRequest)
}

export const LOGIN_APPLE = 'auth/LOGIN_APPLE'
export const logInByApple = (token, code, redirectCallback) => dispatch => {
  dispatch({ type: LOGIN_APPLE })
  logInByAppleRequest(token, code).then((res) => {
    dispatch({ type: LOGIN_APPLE + '.SUCCESS', payload: res.data })
    dispatch(getUser(res.data.token))
    redirectCallback && redirectCallback()
  }).catch((error) => {
    dispatch({ type: LOGIN_APPLE + '.FAILURE', payload: error })
  })
}


export const verifyAppleByToken = (token, code, order, insuranceId, isNewPatientFlow, history, slug) => dispatch => {
  dispatch({ type: LOGIN_APPLE })
  logInByAppleRequest(token, code).then((res) => {
    if(res.data?.user?.apple_sub) {
      dispatch(SetPrefillData(res.data?.user));
      history.push(`/${slug}/signup`);
    } else {
      dispatch({ type: LOGIN_APPLE + '.SUCCESS', payload: res.data });
      dispatch(getUser(res.data.token));
      if (order?.doctor?.id && order?.appointment?.id && isNewPatientFlow) {
        history.push(`/${slug}/confirmation/`)
      } else {
        history.push(`/${slug}/choose-appointment-type`);
      }
    }
  }).catch((error) => {
    dispatch({ type: LOGIN_APPLE + '.FAILURE', payload: error })
  })
}



export const signUpRequest = (data)  => {
  const newRequest = {
    method: 'POST',
    endpoint: `users`,
    query: {
      ...data
    }
  }
  return ApiService.apiCall(newRequest)
}

export const SIGN_UP = 'auth/SIGN_UP';
export const signUp = (data, successAction) => dispatch => {
  dispatch({ type: SIGN_UP })
  signUpRequest(data).then((res) => {
    dispatch({
      type: SIGN_UP + '.SUCCESS',
      payload: res.data,
    })
    successAction(res.data.token)
    dispatch(getUser(res.data.token))
  }).catch((error) => {
    dispatch({
      type: SIGN_UP + '.FAILURE',
      payload: error.response.data,
    })
  })
}

export const GET_CURRENT_USER = 'auth/GET_CURRENT_USER'
export const getCurrentUser = (data) => {
  return {
    ...data,
    type: GET_CURRENT_USER
  }
}

export const SET_IS_CHANGING_INSURANCE = 'auth/SET_IS_CHANGING_INSURANCE'
export const setIsChangingInsurance = (checked) => {
  return {
    checked,
    type: SET_IS_CHANGING_INSURANCE
  }
}

export const LOG_OUT = 'auth/LOG_OUT'
export const logOut = (history) => {
  return (dispatch) => {
    dispatch(onLogOut(history))
    dispatch(clearVerifyState())
    dispatch(clearAppointmentsState())
    dispatch(closeErrorOrSuccessScreen())
  }
}

const onLogOut = (history) => {
  return {
    type: LOG_OUT,
    history
  }
}

export const CLEAR_AUTH_ERRORS = 'auth/CLEAR_AUTH_ERRORS'
export const clearAuthErrors = () => {
  return {
    type: CLEAR_AUTH_ERRORS,
  }
}

export const RESET_STATUS = 'auth/RESET_STATUS'
export const resetStatus = () => {
  return {
    type: RESET_STATUS,
  }
}

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN'
export const refreshTokenAction = ({token, refresh_token}) => {
  return {
    type: REFRESH_TOKEN,
    payload: {
      token,
      refresh_token
    }
  }
}

export const FIND_RECORD = 'auth/FIND_RECORD'
export const findRecord = (data) => {
  return {
    method: 'POST',
    endpoint: `find_record`,
    query: data,
    type: FIND_RECORD,
  }
}

export const CHECK_PHONE = 'auth/CHECK_PHONE'
export const checkPhone = (data) => {
  return {
    method: 'POST',
    endpoint: 'verification/check_phone',
    query: data,
    type: CHECK_PHONE
  }
}

export const SET_DATA_FOR_RECOVERY = 'auth/SET_DATA_FOR_RECOVERY'
export const setDataForRecovery = (data) => {
  return {
    type: SET_DATA_FOR_RECOVERY,
    payload: data,
  }
}

export const SEND_RECOVERY_LINK = 'auth/SEND_RECOVERY_LINK'
export const sendRecoveryLink = (data) => {
  return {
    method: 'POST',
    endpoint: 'password_reset',
    query: data,
    type: SEND_RECOVERY_LINK
  }
}

export const RECOVERY_PASSWORD = 'auth/RECOVERY_PASSWORD'
export const recoveryPassword = (data) => {
  return {
    method: 'POST',
    endpoint: 'password_update',
    query: data,
    type: RECOVERY_PASSWORD
  }
}

export const SET_PREFILL_DATA = 'auth/SET_PREFILL_DATA'
export const SetPrefillData = (data) => {
  return {
    type: SET_PREFILL_DATA,
    payload: data,
  }
}

export const SET_IS_NEW_PATIENT_FLOW = 'auth/SET_IS_NEW_PATIENT_FLOW';
export const setIsNewPatientFlow = (data) => {
  return {
    type: SET_IS_NEW_PATIENT_FLOW,
    payload: data,
  }
}

export const SET_IS_SIGN_IN_ON_PATIENT_FLOW = 'auth/SET_IS_SIGN_IN_ON_PATIENT_FLOW';
export const setIsSignInOnNewPatientFlow = (data) => {
  return {
    type: SET_IS_SIGN_IN_ON_PATIENT_FLOW,
    payload: data,
  }
}

export const SET_IS_AUTH_IN_STAGING = 'auth/SET_IS_AUTH_IN_STAGING';
export const setIsAuthInStaging = (isAuthInStaging) => {
  return {
    type: SET_IS_AUTH_IN_STAGING,
    payload: isAuthInStaging,
  }
}

export const SET_TYPE_AUTHORIZATION = 'auth/SET_TYPE_AUTHORIZATION';
export const setTypeAuthorization = (type) => ({
  type: SET_TYPE_AUTHORIZATION,
  payload: type
})

export const SET_IS_REGISTRATION_FLOW = 'auth/SET_IS_REGISTRATION_FLOW';
export const setIsRegistrationFlow = (isRegistrationFlow) => ({
  type: SET_IS_REGISTRATION_FLOW,
  payload: isRegistrationFlow
})
